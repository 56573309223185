.Footer {
  padding: 2rem 0;
  background: $grey-6;
  color: #fff;
  font-size: 1.6rem;

  &__logo {
    display: block;
    max-width: 54px;
    margin-bottom: .4rem;
  }

  &__title {
    line-height: 1;
    font-size: 2.8rem;
    font-family: $font-heading;
    display: inline-block;
    padding-right: 0.1rem;
    color: #FFF;

    &--small {
      font-size: 2.2rem;
      font-family: $font-heading;
    }
  }

  &__contactInfo {
    @include media-query($medium-down) {
      flex-basis: calc((100% / 2) - #{$gutter-horizontal});
    }

    @include media-query($extra-small) {
      flex-basis: 100%;
    }
  }

  &__leftColumn {
    display: flex;
    flex-direction: column;

    @include media-query($medium-down) {
      order: 1;
      margin-bottom: 0;
      margin-right: 0;
    }

    @include media-query($small) {
      &:not(:last-child) {
        margin-bottom: 0;
        margin-top: $gutter-vertical-sm;
      }
    }
  }

  &__socialsContainer {
    display: flex;
    align-items: center;

    h2 {
      margin-right: 1rem;
    }
  }

  &__socials {
    ul {
      list-style: none;
    }

    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: .6rem;
      }

      a {
        font-size: 1.8rem;  
        display: inline-block;  
        position: relative; 
        color: $grey-6;
        z-index: 2;
        height: 26px;
        width: 26px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 100%;
          background-color: $color-accent;
          z-index: -1;
          transition: .2s all ease-in-out;
        }

        &:hover,
        &:focus {
          &::after {
            background-color: $color-accent-light;
            transform: scale(1.15);
          }
        }
      }
    }
  }

  &__copyright {
    margin: 0;
    margin-top: auto;
  }

  &__address {
    font-style: normal;
  }

  &__phone {
    color: $color-accent;
  }

  &__field {
    display: block;
    letter-spacing: 0.6px;
  }
}
