html {
  font-size: 62.5%;
}

.alignright {
  float: right;
  margin: 20px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 20px 20px 20px 0;
}

.wrapper {
  max-width: $grid-max-width;
  width: 95%;
  margin: 0 auto;

  & > *:not(:last-child) {
    margin-right: $grid-gutter;
  }
}

.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignwide {
  margin-left: -80px;
  margin-right: -80px;
}
.alignfull {
  margin-left: calc(-100vw / 2 + 100% / 2);
  margin-right: calc(-100vw / 2 + 100% / 2);
  max-width: 100vw;
  width: 100vw;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.alignfull img {
  width: 100vw;
}

// Visually Hidden
.visuallyhidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

table {
  width: 100%;
  margin-bottom: 15px;
}

td {
  border: 1px solid black;
  vertical-align: top;
}

sub a,
sup a {
  font-size: inherit;
}

hr {
  margin: 30px 0;
}

body {
  line-height: 1.5;
  overflow-x: hidden;
}

button {
  background: none;
  border: none;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
ul,
p {
  margin: 0px;
  padding: 0px;
}

p,
li,
a,
ul,
ol,
table,
address,
span {
  font-size: $font-size-md;

  @include media-query($medium-down) {
    font-size: 1.6rem;
  }
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

address {
  font-style: normal;
}

@media (max-width: $medium) {
  .alignright,
  .alignleft {
    float: none !important;
    display: block;
    margin: 0 auto;
  }
}

.skiplink {
    position: absolute;
    top: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.skiplink:focus {
  top: 40px;
  width: auto;
  height: auto;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-size: 2rem;
  z-index: 10;
  padding: 10px;
  display: inline-block;
}