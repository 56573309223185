.col1of3 {
    flex-basis: $col-1-of-3;
    
    @include media-query($medium-down) {
        flex-basis: 100%;

        &:not(:last-child) {
            margin-bottom: $gutter-vertical-sm;
        }
    }
}

.col2of3 {
    flex-basis: $col-2-of-3;
}

.col1of2 {
    flex-basis: $col-1-of-2;

    &:not(:last-child) {
        margin-right: $grid-gutter;
    }

    @include media-query($medium-down) {
        flex-basis: 100%;

        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: $gutter-vertical-sm;
        }
    }
}

.row {
    flex-basis: 100%;
}