.Header {
  padding: 1.5rem 0;

  .wrapper.flex {
    align-items: center;
  }

  .logo {
    max-width: 18rem;
    height: auto;
    line-height: 0;

    @include media-query($medium-down) {
      max-width: 12rem;
    }
  }
}

.HeaderNav {
  flex-grow: 1;
  font-weight: 400;
  text-transform: uppercase;
  font-family: $font-paragraph;

  &__container {
    text-align: right;
    margin-left: auto;
  }

  .menu {
    list-style: none;

    &-item {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 3.2rem;
      }
    }
  }

  &__link a {
    font-size: 1.8rem;
    letter-spacing: -0.1px;
    border-bottom: 3px solid transparent;
    transition: .25s all;

    &[aria-current="page"] {
      font-weight: 700;
      cursor: default;
      pointer-events: none;
      border-bottom: 3px solid $color-primary-dark;
    }

    &:not([aria-current="page"]) {
      &:hover,
      &:focus {
        color: $color-primary-dark;
      }

      &:focus {
        border-bottom: 3px solid $color-primary-dark;
      }
    }
  }

  .toggleButton {
    display: none;
  }

  @include media-query($medium-down) {
    text-align: right;

    .toggleButton {
      display: block;
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 10px;
      position: relative;
      z-index: 999;
    }

    &__container{
      &.open {
        transform: translateX(0);
      }
      position: absolute;
      top: 0;
      right: 0;
      z-index: 99;
      width: 100%;
      height: 100vh;
      transform: translateX(100%);
      background-color: #FFF;
      transition: .2s all;
      padding: 10rem 0;
    }

    .menu {
      padding: 15% 5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .menu-item {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
