/*!
* Theme Name: One Stop
* Description: Custom Theme for an automotive shop
* Version: 1.0
* Author: Russell Bentulan
* Author URI: https://russellbentulan.com/
*/
/*
=============================================================================
                   Normalize, border box and clearfix
=============================================================================
*/
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: \201C \201D \2018 \2019; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, html input[type=button],
input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, figure {
  margin: 0; }

legend, button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.clearfix:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

html {
  font-size: 62.5%; }

.alignright {
  float: right;
  margin: 20px 0 20px 20px; }

.alignleft {
  float: left;
  margin: 20px 20px 20px 0; }

.wrapper {
  max-width: 114rem;
  width: 95%;
  margin: 0 auto; }
  .wrapper > *:not(:last-child) {
    margin-right: 30px; }

.aligncenter {
  display: block;
  margin: 0 auto; }

.alignwide {
  margin-left: -80px;
  margin-right: -80px; }

.alignfull {
  margin-left: calc(-100vw / 2 + 100% / 2);
  margin-right: calc(-100vw / 2 + 100% / 2);
  max-width: 100vw;
  width: 100vw;
  padding-left: 0 !important;
  padding-right: 0 !important; }

.alignfull img {
  width: 100vw; }

.visuallyhidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden; }

table {
  width: 100%;
  margin-bottom: 15px; }

td {
  border: 1px solid black;
  vertical-align: top; }

sub a,
sup a {
  font-size: inherit; }

hr {
  margin: 30px 0; }

body {
  line-height: 1.5;
  overflow-x: hidden; }

button {
  background: none;
  border: none; }

.flex {
  display: flex;
  flex-wrap: wrap; }

a {
  color: inherit;
  text-decoration: none; }

h1,
h2,
h3,
h4,
h5,
ul,
p {
  margin: 0px;
  padding: 0px; }

p,
li,
a,
ul,
ol,
table,
address,
span {
  font-size: 1.8rem; }
  @media only screen and (max-width: 49em) {
    p,
    li,
    a,
    ul,
    ol,
    table,
    address,
    span {
      font-size: 1.6rem; } }

img {
  max-width: 100%;
  height: auto;
  display: block; }

address {
  font-style: normal; }

@media (max-width: medium) {
  .alignright,
  .alignleft {
    float: none !important;
    display: block;
    margin: 0 auto; } }

.skiplink {
  position: absolute;
  top: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.skiplink:focus {
  top: 40px;
  width: auto;
  height: auto;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-size: 2rem;
  z-index: 10;
  padding: 10px;
  display: inline-block; }

body {
  font-family: "Acumin Pro", sans-serif;
  color: #333544; }

h1,
h2,
h3,
h4 {
  font-size: 2.4rem; }

p {
  margin-bottom: 2rem;
  line-height: 1.4; }

[class^="heading--"] {
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Rift", sans-serif;
  font-style: italic;
  margin-bottom: 2rem; }

.heading--primary {
  font-size: 6.2rem;
  line-height: 1;
  letter-spacing: 1.5px; }

.heading--secondary {
  font-size: 4.2rem;
  letter-spacing: 0.1px; }

.heading--underlined {
  display: block;
  position: relative; }
  .heading--underlined::after {
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    position: relative;
    bottom: 10%;
    background: linear-gradient(179deg, #ec1f27 50%, #c1161d 50%);
    transform: skewX(-15deg); }

.textContainer {
  align-self: center; }

.btn--arrow {
  font-weight: 700;
  font-family: "Rift", sans-serif;
  font-size: 2.4rem;
  letter-spacing: 0.5px;
  display: inline-block;
  transform: skewX(-5deg);
  padding: 4px 1.2rem;
  text-align: center;
  line-height: 1.4;
  transition: .35s all ease-in-out;
  background: #f8cb57;
  overflow: hidden;
  position: relative; }
  .btn--arrow::after {
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f101";
    margin-left: 5px;
    color: #c1161d;
    transition: .2s all ease-out;
    transition-delay: .2s; }
  .btn--arrow::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #c1161d;
    z-index: -1;
    transform: translateX(-100%);
    transition: .35s transform ease-in-out; }
  .btn--arrow:hover, .btn--arrow:focus {
    color: #FFF; }
    .btn--arrow:hover::after, .btn--arrow:focus::after {
      margin-left: 12px;
      color: #fb595e; }
    .btn--arrow:hover::before, .btn--arrow:focus::before {
      transform: translateX(0); }

.btn, .wpcf7 input[type="submit"],
[class*="wpcf7"] input[type="submit"] {
  font-family: "Rift", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #333544;
  padding: 1.2rem 2rem;
  letter-spacing: 0.8px;
  display: inline-block;
  overflow: hidden;
  position: relative; }
  .btn::after, .wpcf7 input[type="submit"]::after,
  [class*="wpcf7"] input[type="submit"]::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(145deg, rgba(0, 0, 0, 0) 55%, #dea01e 55%);
    z-index: -1;
    transition: 0.25s all ease-out; }
  .btn::before, .wpcf7 input[type="submit"]::before,
  [class*="wpcf7"] input[type="submit"]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #f8cb57;
    z-index: -1;
    transition: 0.25s all ease-in-out; }
  .btn:hover, .wpcf7 input:hover[type="submit"],
  [class*="wpcf7"] input:hover[type="submit"], .btn:focus, .wpcf7 input:focus[type="submit"],
  [class*="wpcf7"] input:focus[type="submit"] {
    color: #4c4e70; }
    .btn:hover::after, .wpcf7 input[type="submit"]:hover::after,
    [class*="wpcf7"] input[type="submit"]:hover::after, .btn:focus::after, .wpcf7 input[type="submit"]:focus::after,
    [class*="wpcf7"] input[type="submit"]:focus::after {
      transform: translateX(15%);
      opacity: 0; }
    .btn:hover::before, .wpcf7 input[type="submit"]:hover::before,
    [class*="wpcf7"] input[type="submit"]:hover::before, .btn:focus::before, .wpcf7 input[type="submit"]:focus::before,
    [class*="wpcf7"] input[type="submit"]:focus::before {
      background-color: #fce79a; }

#comments .navigation {
  padding: 0 0 18px; }

h3#comments-title, h3#reply-title {
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0; }

h3#comments-title {
  padding: 24px 0; }

.commentlist li.comment {
  border-bottom: 1px solid #e7e7e7;
  line-height: 24px;
  position: relative;
  clear: both;
  margin: 0 0 24px;
  padding: 0 0 0 56px; }

.commentlist li:last-child {
  border-bottom: none;
  margin-bottom: 0; }

#comments .comment-body ul, #comments .comment-body ol {
  margin-bottom: 18px; }

#comments .comment-body p:last-child {
  margin-bottom: 6px; }

#comments .comment-body blockquote p:last-child {
  margin-bottom: 24px; }

.commentlist ol {
  list-style: decimal; }

.commentlist .avatar {
  position: absolute;
  top: 4px;
  left: 0; }

.comment-author cite {
  color: #000000;
  font-style: normal;
  font-weight: 700; }

.comment-author .says {
  font-style: italic; }

.comment-meta {
  font-size: 12px;
  margin: 0 0 5px; }
  .comment-meta a:link, .comment-meta a:visited {
    color: #888888;
    text-decoration: none; }

.reply {
  font-size: 12px;
  padding: 0 0 24px; }
  .reply a {
    color: #888888; }

a.comment-edit-link {
  color: #888888; }

.commentlist .children li {
  border: none;
  margin: 0; }

#comments .pingback {
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e7e7e7; }

.commentlist li.comment + li.pingback {
  margin-top: -6px; }

#comments .pingback p {
  color: #888888;
  font-size: 12px;
  line-height: 18px;
  display: block;
  margin: 0; }

#comments .pingback .url {
  font-style: italic;
  font-size: 13px; }

input[type=submit] {
  color: #333333; }

#respond {
  margin-top: 24px;
  border-top: 1px solid #e7e7e7;
  overflow: hidden;
  position: relative; }
  #respond .comment-notes {
    margin-bottom: 1em; }

.form-allowed-tags {
  line-height: 1em; }

.children #respond {
  margin: 0 48px 0 0; }

h3#reply-title {
  margin: 18px 0; }

#comments-list #respond {
  margin: 0 0 18px; }

#cancel-comment-reply-link {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400; }

#respond .required {
  color: #ff4b33;
  font-weight: 700; }

#respond label {
  color: #888888;
  font-size: 12px; }

#respond input {
  width: 98%;
  margin: 0 0 9px; }

#respond textarea {
  width: 98%; }

#respond dt {
  color: #888888;
  font-size: 12px;
  line-height: 18px; }

#respond dd {
  color: #888888;
  font-size: 12px;
  line-height: 18px; }
  #respond dd code {
    font-size: 11px; }

#respond .form-submit {
  margin: 0 0 24px; }
  #respond .form-submit input {
    width: auto;
    font-size: 14px; }

#respond dl {
  margin: 0 0 10px; }

#respond dt {
  display: inline;
  font-weight: 400; }

#respond dd {
  display: inline; }

p.form-allowed-tags, .nocomments {
  display: none; }

.commentlist {
  list-style: none;
  margin: 0; }
  .commentlist .children {
    list-style: none;
    margin: 0; }

.comment-meta a:active, .comment-meta a:hover {
  color: #ff4b33; }

.reply a:hover, a.comment-edit-link:hover {
  color: #ff4b33; }

#respond p, #comments-list ul #respond {
  margin: 0; }

.toggleBar {
  background-color: #222235;
  border-radius: 25px;
  height: 5px;
  transition: 0.2s all; }
  .toggleBar:not(:last-of-type) {
    margin-bottom: 3.5px; }

.toggleButton {
  margin: 0 auto;
  display: block;
  background-color: transparent;
  width: 50px;
  font-size: 16px; }
  .toggleButton:hover .HeaderNav__toggleBar {
    background-color: #861417; }
  .toggleButton:focus, .toggleButton:active {
    outline: 0; }
    .toggleButton:focus .toggleBar, .toggleButton:active .toggleBar {
      transform: scale(1.2); }
  .toggleButton.open {
    transition: 0.2s transform; }
    .toggleButton.open:focus {
      transform: scale(1.2); }
    .toggleButton.open .toggleBar {
      background-color: #222235; }
      .toggleButton.open .toggleBar.one {
        transform: translate(0, 8.55px) rotate(45deg); }
      .toggleButton.open .toggleBar.two {
        transform: translateX(-5em);
        box-shadow: none;
        opacity: 0; }
        .toggleButton.open .toggleBar.two:focus, .toggleButton.open .toggleBar.two:active {
          border: 0; }
      .toggleButton.open .toggleBar.three {
        transform: translate(0, -8.55px) rotate(-45deg); }

.Banner__bg {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 45vh;
  display: flex;
  align-items: flex-end; }

.Banner__titleContainer {
  padding: 1rem 2.5rem 1rem 5%;
  display: inline-block;
  background: linear-gradient(120deg, #005c97, #363795);
  clip-path: polygon(0 0, 0 100%, 100% 100%, calc(100% - 1rem) 0);
  color: #FFF;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.32); }
  .Banner__titleContainer h1 {
    margin-bottom: 0; }

.PageContent {
  padding: 8rem 0; }
  .PageContent h1,
  .PageContent h2,
  .PageContent h3,
  .PageContent h4 {
    margin-bottom: 1.6rem; }

.cta {
  margin: 1.6rem 0;
  border: 1rem solid #222235;
  border-left: 0.8rem solid #222235;
  border-right: 0.8rem solid #222235; }
  .cta:last-child {
    margin-bottom: 0; }
  .cta::after {
    content: "";
    width: 100%;
    height: .8rem;
    background: #222235; }
  .cta__title {
    text-align: center;
    font-family: "Rift", sans-serif;
    font-style: italic;
    font-size: 4.2rem;
    background-color: #222235;
    color: #FFF; }
  .cta .textContainer {
    padding: 0 5%; }

.col1of3 {
  flex-basis: calc( (100% - 30px * 2) / 3); }
  @media only screen and (max-width: 49em) {
    .col1of3 {
      flex-basis: 100%; }
      .col1of3:not(:last-child) {
        margin-bottom: 1.6rem; } }

.col2of3 {
  flex-basis: calc( (100% - 30px) / 3 * 2); }

.col1of2 {
  flex-basis: calc( (100% - 30px) / 2); }
  .col1of2:not(:last-child) {
    margin-right: 30px; }
  @media only screen and (max-width: 49em) {
    .col1of2 {
      flex-basis: 100%; }
      .col1of2:not(:last-child) {
        margin-right: 0;
        margin-bottom: 1.6rem; } }

.row {
  flex-basis: 100%; }

.Header {
  padding: 1.5rem 0; }
  .Header .wrapper.flex {
    align-items: center; }
  .Header .logo {
    max-width: 18rem;
    height: auto;
    line-height: 0; }
    @media only screen and (max-width: 49em) {
      .Header .logo {
        max-width: 12rem; } }

.HeaderNav {
  flex-grow: 1;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Acumin Pro", sans-serif; }
  .HeaderNav__container {
    text-align: right;
    margin-left: auto; }
  .HeaderNav .menu {
    list-style: none; }
    .HeaderNav .menu-item {
      display: inline-block; }
      .HeaderNav .menu-item:not(:last-child) {
        margin-right: 3.2rem; }
  .HeaderNav__link a {
    font-size: 1.8rem;
    letter-spacing: -0.1px;
    border-bottom: 3px solid transparent;
    transition: .25s all; }
    .HeaderNav__link a[aria-current="page"] {
      font-weight: 700;
      cursor: default;
      pointer-events: none;
      border-bottom: 3px solid #c1161d; }
    .HeaderNav__link a:not([aria-current="page"]):hover, .HeaderNav__link a:not([aria-current="page"]):focus {
      color: #c1161d; }
    .HeaderNav__link a:not([aria-current="page"]):focus {
      border-bottom: 3px solid #c1161d; }
  .HeaderNav .toggleButton {
    display: none; }
  @media only screen and (max-width: 49em) {
    .HeaderNav {
      text-align: right; }
      .HeaderNav .toggleButton {
        display: block;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 10px;
        position: relative;
        z-index: 999; }
      .HeaderNav__container {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99;
        width: 100%;
        height: 100vh;
        transform: translateX(100%);
        background-color: #FFF;
        transition: .2s all;
        padding: 10rem 0; }
        .HeaderNav__container.open {
          transform: translateX(0); }
      .HeaderNav .menu {
        padding: 15% 5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .HeaderNav .menu-item:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px; } }

.Footer {
  padding: 2rem 0;
  background: #222235;
  color: #fff;
  font-size: 1.6rem; }
  .Footer__logo {
    display: block;
    max-width: 54px;
    margin-bottom: .4rem; }
  .Footer__title {
    line-height: 1;
    font-size: 2.8rem;
    font-family: "Rift", sans-serif;
    display: inline-block;
    padding-right: 0.1rem;
    color: #FFF; }
    .Footer__title--small {
      font-size: 2.2rem;
      font-family: "Rift", sans-serif; }
  @media only screen and (max-width: 49em) {
    .Footer__contactInfo {
      flex-basis: calc((100% / 2) - 2.5vw); } }
  @media only screen and (max-width: 26em) {
    .Footer__contactInfo {
      flex-basis: 100%; } }
  .Footer__leftColumn {
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 49em) {
      .Footer__leftColumn {
        order: 1;
        margin-bottom: 0;
        margin-right: 0; } }
    @media only screen and (max-width: 35em) {
      .Footer__leftColumn:not(:last-child) {
        margin-bottom: 0;
        margin-top: 1.6rem; } }
  .Footer__socialsContainer {
    display: flex;
    align-items: center; }
    .Footer__socialsContainer h2 {
      margin-right: 1rem; }
  .Footer__socials ul {
    list-style: none; }
  .Footer__socials li {
    display: inline-block; }
    .Footer__socials li:not(:last-child) {
      margin-right: .6rem; }
    .Footer__socials li a {
      font-size: 1.8rem;
      display: inline-block;
      position: relative;
      color: #222235;
      z-index: 2;
      height: 26px;
      width: 26px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .Footer__socials li a::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 100%;
        background-color: #f8cb57;
        z-index: -1;
        transition: .2s all ease-in-out; }
      .Footer__socials li a:hover::after, .Footer__socials li a:focus::after {
        background-color: #fce79a;
        transform: scale(1.15); }
  .Footer__copyright {
    margin: 0;
    margin-top: auto; }
  .Footer__address {
    font-style: normal; }
  .Footer__phone {
    color: #f8cb57; }
  .Footer__field {
    display: block;
    letter-spacing: 0.6px; }

.Hero {
  position: relative;
  color: #fff;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.32);
  font-size: 1.8rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media only screen and (max-width: 49em) {
    .Hero {
      background-size: 150% auto;
      background-position: top; } }
  .Hero__bg {
    background: linear-gradient(to bottom, rgba(33, 68, 91, 0.45), rgba(54, 55, 149, 0.35));
    height: 100%;
    position: relative; }
    .Hero__bg::after {
      content: "";
      position: absolute;
      left: 50%;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(120deg, #005c97, #363795);
      clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0 100%); }
    @media only screen and (max-width: 49em) {
      .Hero__bg::after {
        display: none; }
      .Hero__bg .wrapper {
        width: 100%; } }
  .Hero__textContainer {
    align-self: center;
    margin: 8rem 0 8rem auto;
    max-width: 40%;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 2; }
    @media only screen and (max-width: 49em) {
      .Hero__textContainer {
        max-width: 100%;
        margin: 30% 0 0 0;
        padding: 8rem 10%;
        background: linear-gradient(120deg, #005c97, #363795);
        clip-path: polygon(0% 10%, 0 100%, 100% 100%, 100% 0); } }
  .Hero__title {
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.32); }
    @media only screen and (max-width: 49em) {
      .Hero__title {
        font-size: 4.2rem; } }
  .Hero__btn {
    font-size: 2.4rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    text-shadow: none;
    transition: all 0.2s; }
    .Hero__btn:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

.About {
  padding: 8rem 0;
  background: linear-gradient(181deg, #dfdfe7 8%, rgba(0, 0, 0, 0) 8%); }
  .About .wrapper {
    align-items: center; }
  .About__imgContainer {
    display: block; }
  .About__field {
    display: block; }

.wpcf7 *,
[class*="wpcf7"] * {
  width: 100%; }

.wpcf7 input:not([type="submit"]),
.wpcf7 textarea,
[class*="wpcf7"] input:not([type="submit"]),
[class*="wpcf7"] textarea {
  padding: 1rem .6rem;
  line-height: 1;
  border: 1.2px solid #7c7d9c; }
  .wpcf7 input:not([type="submit"]):focus,
  .wpcf7 textarea:focus,
  [class*="wpcf7"] input:not([type="submit"]):focus,
  [class*="wpcf7"] textarea:focus {
    border: 1.2px solid #005c97;
    box-shadow: 0 0 1.6px 1.6px rgba(178, 133, 43, 0.45); }

.wpcf7 input[type="submit"],
[class*="wpcf7"] input[type="submit"] {
  background-color: #f8cb57;
  border: 1.2px solid #b2852b;
  transition: .2s all ease-in-out; }
  .wpcf7 input[type="submit"]:hover,
  [class*="wpcf7"] input[type="submit"]:hover {
    background-color: #b2852b;
    color: #FFF; }

.wpcf7 label,
[class*="wpcf7"] label {
  font-family: "Rift", sans-serif; }

.ShopInfo {
  margin-bottom: 30px; }
  .ShopInfo__location {
    height: 50vh;
    min-height: 450px; }
  .ShopInfo__field {
    display: block; }
  .ShopInfo__address {
    margin-bottom: 1.6rem; }
