body {
  font-family: $font-paragraph;
  color: $grey-5;
}

h1,
h2,
h3,
h4 {
  font-size: $font-size-lg;
}

p {
  margin-bottom: $gutter-paragraph;
  line-height: 1.4;
}

[class^="heading--"] {
  text-transform: uppercase;
  font-weight: 700;
  font-family: $font-heading;
  font-style: italic;
  margin-bottom: 2rem;
}

.heading {
  &--primary {
    font-size: 6.2rem;
    line-height: 1;
    letter-spacing: 1.5px;
  }

  &--secondary {
    font-size: 4.2rem;
    letter-spacing: 0.1px;
  }

  &--underlined {
    display: block;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      position: relative;
      bottom: 10%;
      background: linear-gradient(179deg, $color-primary 50%, $color-primary-dark 50%);
      transform: skewX(-15deg);
    }
  }
}
