.btn--arrow {
  font-weight: 700;
  font-family: $font-heading;
  font-size: $font-size-lg;
  letter-spacing: 0.5px;
  display: inline-block;
  transform: skewX(-5deg);
  padding: 4px 1.2rem;
  text-align: center;
  line-height: 1.4;
  transition: .35s all ease-in-out;
  background: $color-accent;
  overflow: hidden;
  position: relative;
  
  &::after {
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f101";
    margin-left: 5px;
    color: $color-primary-dark;
    transition: .2s all ease-out;
    transition-delay: .2s;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-primary-dark;
    z-index: -1;
    transform: translateX(-100%);
    transition: .35s transform ease-in-out;
  }

  &:hover,
  &:focus {
    color: #FFF;

    &::after {
      margin-left: 12px;
      color: $color-primary-light;
    }
    
    &::before {
      transform: translateX(0);
    }
  }
}

.btn {
  font-family: $font-heading;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: $grey-5;
  padding: 1.2rem 2rem;
  letter-spacing: 0.8px;
  display: inline-block;
  overflow: hidden;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient((145deg, rgba(0, 0, 0, 0) 55%, #dea01e 55%));
    z-index: -1;
    transition: 0.25s all ease-out;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $color-accent;
    z-index: -1;
    transition: 0.25s all ease-in-out;
  }

  &:hover,
  &:focus {
    color: $grey-4;

    &::after {
      transform: translateX(15%);
      opacity: 0;
    }

    &::before {
      background-color: $color-accent-light;
    }
  }
}
