/*!
* Theme Name: One Stop
* Description: Custom Theme for an automotive shop
* Version: 1.0
* Author: Russell Bentulan
* Author URI: https://russellbentulan.com/
*/
@import 'abstracts/variables',
        'abstracts/mixins';

@import 'base/normalize',
        'base/base',
        'base/typography';

@import 'util/textContainer';
        
@import 'components/buttons',
        'components/comments',
        'components/toggleButton',
        'components/Banner',
        'components/PageContent',
        'components/blockCta';

@import 'layout/flexGrid',
        'layout/header',
        'layout/footer';

@import 'pages/home',
        'pages/contact';
