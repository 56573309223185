.Hero {
  position: relative;
  color: $color-secondary-light;
  text-shadow: $shadow-text;
  font-size: 1.8rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include media-query($medium-down) {
    background-size: 150% auto;
    background-position: top;
  }

  &__bg {
    background: linear-gradient(
      to bottom,
      rgba($color-secondary-dark, 0.45),
      rgba($color-tertiary, 0.35)
    );
    height: 100%;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(120deg, $color-secondary, $color-tertiary);
      clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0 100%);
    }

    @include media-query($medium-down) {
      &::after {
        display: none;
      }
      .wrapper {
        width: 100%;
      }
    }
  }

  &__textContainer {
    align-self: center;
    margin: $gutter-vertical 0 $gutter-vertical auto;
    max-width: 40%;
    line-height: 1.2;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    z-index: 2;

    @include media-query($medium-down) {
      max-width: 100%;
      margin: 30% 0 0 0;
      padding: $gutter-vertical 10%;
      background: linear-gradient(120deg, $color-secondary, $color-tertiary);
      clip-path: polygon(0% 10%, 0 100%, 100% 100%, 100% 0);
    }
  }

  &__title {
    text-shadow: $shadow-text;

    @include media-query($medium-down) {
      font-size: 4.2rem;
    }
  }

  &__btn {
    font-size: 2.4rem;
    box-shadow: $shadow-2;
    text-shadow: none;
    transition: all 0.2s;

    &:hover {
      box-shadow: $shadow-3;
    }
  }
}

.About {
  padding: $gutter-vertical 0;
  background: linear-gradient(181deg, $grey-1 8%, rgba(0, 0, 0, 0) 8%);

  .wrapper {
    align-items: center;
  }

  &__imgContainer {
    display: block;
  }

  &__field {
    display: block;
  }
}
